.ant-layout-content{
    position: relative;
}

.filter-panel-filter-item{
    margin-right: 5px;
    background: #faad14;
    display: inline-block;
    font-size: smaller;
    line-height: 1.3;
    padding: 0 5px;
    border-radius: 50px;
    color: black;
    border: 1px solid white;
}

.state-toggle{
    color: #009688;
    padding: 5px 10px;
    border: 1px solid #009688;
    border-radius: 3px;
}